<template>
  <mdb-container>
    <Adminheader></Adminheader>
    <section class="mt-3">
      <mdb-row>
        <mdb-col xl="6" md="6" class="mb-4">
          <mdb-btn outline v-on:click="gotowebinarslist" color="primary"><mdb-icon icon="arrow-left" class="mr-1"/> Retour à la liste des webinaires</mdb-btn>
        </mdb-col>
      </mdb-row>
      <mdb-row>
        <mdb-col xl="12" md="12" class="mb-4">
          <mdb-card >
            <mdb-card-header color="orange accent-3">Nouveau Webinaire</mdb-card-header>
            <mdb-card-body>
              <mdb-alert color="danger" v-if="errormsg">
                {{errormsg}}
              </mdb-alert>
              <mdb-container>
                <mdb-row>
                  <mdb-col md="12">
                      <mdb-input class="" label="ID Youtube" v-model="webinaire.youtubeid" />
                      <mdb-alert color="danger" v-if="youtubevalidateerrmsg">
                        {{youtubevalidateerrmsg}}
                      </mdb-alert>
                      <mdb-btn outline="elegant" size="sm" v-on:click="validateYoutubeId">
                        Valider le ID Youtube
                        <span class="spinner-border spinner-border-sm ml-3" role="status" aria-hidden="true" v-if="youtubevalidationisloading"></span>
                      </mdb-btn>
                    <hr />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Titre" v-model="webinaire.title" required />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <label class="">Webinaire Test</label>
                    <mdb-alert color="warning">Cette option permet de créer un webinaire test qui ne sera accessible que par un lien provenant du conseiller Justin Démaux.<br /><strong>Il est impossible d'enlever le mode Test après l'avoir sélectionné.</strong></mdb-alert>
                    <mdb-switch v-model="webinaire.istest" onLabel="Oui" offLabel="Non" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Date / heure du webinaire" :value="handlePickersValue()" @click.native="$refs.datePicker.open()" required />
                    <mdb-date-picker-2
                      ref="datePicker"
                      disable-input
                      v-model="webinaire.thedate"
                      disabledPast
                      @close="$refs.timePicker.open()" />
                    <mdb-time-picker
                      ref="timePicker"
                      disable-input
                      v-model="webinaire.thehour" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Présenté par" v-model="webinaire.presentedby" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Mini description" :rows="2" maxlength="64" counter :counterMaxValue="64" required v-model="webinaire.minidesc" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Description complète" :rows="7" required v-model="webinaire.fulldesc" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Contenu du courriel à envoyer après la présentation" :rows="15" required v-model="webinaire.afterwebinaremailcontent" />
                    <mdb-switch onLabel="Envoyer le courriel"
                              offLabel="Ne pas envoyer le courriel" v-model="webinaire.sendmail"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Contenu du courriel à envoyer après la présentation aux agents" :rows="15" required v-model="webinaire.afterwebinaragentemailcontent" />
                    <p class="font-small blue-text d-flex justify-content-start">Le contenu de ce champ sera ajouté au courriel récapitulatif envoyé après la présentation</p>
                    <hr />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input type="textarea" label="Contenu du courriel à envoyer après la présentation aux non-participants" :rows="15" required v-model="webinaire.afterwebinarnonattendeeemailcontent" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-alert color="info">
                      Pour des résultats optimaux, la taille de l'image doit etre de 730x400 pixels
                    </mdb-alert>
                    <mdb-file-input class="align-items" @getValue="getFileInputValue" :width="730" :height="400" btnColor="mdb-color" btn-title="Image de couverture" rounded img src="https://mdbootstrap.com/img/Photos/Others/placeholder.jpg" required />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <h3>Informations Youtube complémentaires</h3>
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Stream Key" v-model="webinaire.youtubestreamkey" />
                  </mdb-col>
                </mdb-row>
                <mdb-row>
                  <mdb-col md="12">
                    <mdb-input label="Stream Server Url" v-model="webinaire.youtubestreamsvrurl" />
                  </mdb-col>
                </mdb-row>
                <mdb-btn color="orange accent-3" v-if="isSaving===false" v-on:click="saveinfos()">Sauvegarder</mdb-btn>
              </mdb-container>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <mdb-modal :show="isSaving" info :centered="true">
      <mdb-modal-header :close="false">
        <p class="heading">Sauvegarde en cours...</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <mdb-row>
          <mdb-col col="12" class="pt-3">
            <div class="d-flex justify-content-center">
              <mdb-spinner big multicolor />
            </div>
          </mdb-col>
        </mdb-row>
      </mdb-modal-body>
    </mdb-modal>
  </mdb-container>
</template>

<script>
    import Vue from 'vue';
    import Adminheader from '@/components/Adminheader.vue';

    import {
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbIcon,
        mdbCard,
        mdbCardHeader,
        mdbCardBody,
        mdbInput,
        mdbDatePicker2,
        mdbTimePicker,
        mdbFileInput,
        mdbAlert,
        mdbModal,
        mdbModalHeader,
        mdbModalBody,
        mdbSpinner,
        mdbSwitch,
    } from 'mdbvue';
    import Auth from '../libs/Auth';
    import Apicall from '../libs/Apicall';

    export default Vue.extend({
        name: 'Dashboard',
        components: {
            Adminheader,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbBtn,
            mdbIcon,
            mdbCard,
            mdbCardHeader,
            mdbCardBody,
            mdbInput,
            mdbDatePicker2,
            mdbTimePicker,
            mdbFileInput,
            mdbAlert,
            mdbModal,
            mdbModalHeader,
            mdbModalBody,
            mdbSpinner,
            mdbSwitch,
        },
        data() {
            return {
                webinaire: {
                  title: '',
                  thedate: '',
                  thehour: '',
                  presentedby: '',
                  minidesc: '',
                  fulldesc: '',
                  imageblobbase64: '',
                  imageoriginalfilename: '',
                  youtubeid: '',
                  youtubestreamkey: '',
                  youtubestreamsvrurl: 'rtmp://a.rtmp.youtube.com/live2',
                  istest: false,
                  afterwebinaremailcontent: 'Bonjour {{ registration.firstname }},\n'
                      + '\n'
                      + 'Merci beaucoup d’avoir participé à la conférence {{ webinar.name }}.\n'
                      + '\n'
                      + 'Nous espérons que vous avez trouvé l’inspiration et les informations pertinentes que vous recherchiez.\n'
                      + '\n'
                      + 'En tout temps, vous pouvez contacter votre conseiller en voyages en utilisant les coordonnées qui se trouvent ci-dessous.\n'
                      + '\n'
                      + 'Bon voyage!\n',
                  afterwebinaragentemailcontent: '',
                  afterwebinarnonattendeeemailcontent: 'Bonjour {{ registration.firstname }},\n'
                    + '\n'
                    + 'Merci beaucoup pour votre inscription à la conférence {{ webinar.name }}.\n'
                    + '\n'
                    + 'Il semble que vous n’ayez pas pu assister à cette dernière. Pas de problème! Vous trouverez ci-dessous, le lien pour l’écouter dans le confort de votre foyer et quand vous en aurez le temps.\n'
                    + '\n'
                    + '<a style="font-weight: bold;" href="https://www.youtube.com/watch?v={{ webinar.youtubeid }}" target="_blank">Accédez à la conférence</a>\n'
                    + '\n'
                    + 'Et bonne nouvelle! Parce que vous étiez parmi les inscrits à cette conférence, vous avez accès vous-aussi à toutes les promotions exclusives qui auraient pu être mentionnées durant la présentation. Attention, celles-ci ont souvent une durée très limitée.\n'
                    + '\n'
                    + 'Bonne écoute et au plaisir d’échanger avec vous,\n'
                    + '\n',
                  sendmail: false,
                },
                datePickerValue: '',
                timePickerValue: '',
                isSaving: false,
                errormsg: '',
                youtubevalidateerrmsg: '',
                youtubevalidationisloading: false,
            };
        },
        mounted() {
            Auth.validateIfCanAccess('ADMINISTRATOR', 'Dashboard');
        },
        methods: {
            test() {
                console.log('test');
            },
            gotowebinarslist() {
                this.$router.push({ name: 'Webinairelist' });
            },
            handlePickersValue() {
                let output = '';
                if (this.webinaire.thedate && this.webinaire.thehour) output = `${this.webinaire.thedate}, ${this.webinaire.thehour}`;
                else if (this.webinaire.thedate) output = this.webinaire.thedate;
                else if (this.webinaire.thehour) output = this.webinaire.thehour;
                return output;
            },
            getFileInputValue(val) {
                const reader = new FileReader();
                const thefile = val[0];
                const self = this;
                reader.onload = (() => {
                    self.webinaire.imageblobbase64 = btoa(reader.result);
                });
                reader.readAsBinaryString(thefile);
                this.webinaire.imageoriginalfilename = thefile.name;
            },
            saveinfos() {
                this.isSaving = true;
                const self = this;
                const api = new Apicall();
                this.errormsg = '';
                api.addWebinaire(this.webinaire).then((response) => {
                    if (response.status === 'OK') {
                        self.$router.push({ name: 'Viewwebinaire', params: { ident: response.data.ident } });
                    } else {
                        self.errormsg = response.msg;
                    }
                    self.isSaving = false;
                });
                window.scrollTo(0, 0);
            },
            validateYoutubeId() {
                this.youtubevalidationisloading = true;
                const self = this;
                const api = new Apicall();
                api.validateYoutubeId(this.webinaire.youtubeid).then((response) => {
                    self.youtubevalidationisloading = false;
                    if (response.status === 'OK') {
                        self.youtubevalidateerrmsg = false;
                        self.webinaire.title = response.data.yt.title;
                        self.webinaire.thehour = response.data.yt.scheduledStartTimeTime;
                        self.webinaire.thedate = response.data.yt.scheduledStartTimeDate;
                        self.webinaire.fulldesc = response.data.yt.description;
                    } else {
                        self.youtubevalidateerrmsg = response.msg;
                    }
                });
            },
        },
    });
</script>

<style scoped>
  .img-placeholder{
    align-items: unset!important;
  }
</style>
